import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import palette from '../../styles/palette';
import { InputVariant } from './types';

type InputStatus = 'disabled' | 'error';

export const variant: {
  [key in InputVariant]: {
    styles: SerializedStyles;
  };
} = {
  outlined: {
    styles: css`
      border: solid 1px ${palette.gray400};

      &:hover {
        border: solid 1px ${palette.gray600};
      }

      &:active {
        border: solid 2px ${palette.main_blue1};
      }

      &:focus {
        border: solid 2px ${palette.main_blue1};
      }
    `,
  },
  underlined: {
    styles: css`
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom: solid 1px ${palette.gray600};
      background-color: ${palette.gray30};
      border-right: 0px;
      border-top: 0px;
      border-left: 0px;

      &:hover {
        border-bottom: solid 1px ${palette.gray600};
      }

      &:active {
        border-bottom: solid 2px ${palette.main_blue1};
      }

      &:focus {
        border-bottom: solid 2px ${palette.main_blue1};
      }
    `,
  },
};

export const status: {
  [key in InputVariant]: {
    [key in InputStatus]: {
      styles: SerializedStyles;
    };
  };
} = {
  outlined: {
    error: {
      styles: css`
        border: solid 2px ${palette.red0} !important;
      `,
    },
    disabled: {
      styles: css`
        border: solid 1px ${palette.gray400} !important;
        background-color: ${palette.gray100} !important;
        color: ${palette.gray500} !important;
        cursor: not-allowed;
      `,
    },
  },
  underlined: {
    error: {
      styles: css`
        border-bottom: solid 2px ${palette.red0} !important;
      `,
    },
    disabled: {
      styles: css`
        border-bottom: solid 1px ${palette.gray400} !important;
        background-color: ${palette.gray100} !important;
        color: ${palette.gray500} !important;
        cursor: not-allowed;
      `,
    },
  },
};
