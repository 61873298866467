import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import palette from '../../styles/palette';
import { ButtonSize, ButtonVariant, ButtonColor } from './types';

const common = css`
  font-size: 14px;
  line-height: 20px;
  padding: 4px 4px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  align-items: center;

  i {
    display: flex;
    flex: 0 0 auto;
  }

  svg {
    margin: 2px;
    width: 16px;
    height: 16px;
  }

  img {
    margin: 2px;
    width: auto;
    height: 16px;
  }

  span {
    margin: 0 4px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
  }

  &:hover {
    transition: ease 0.2s;
  }
`;

export const size: {
  [key in ButtonSize]: {
    styles: SerializedStyles;
  };
} = {
  small: { styles: common },
  medium: {
    styles: css`
      ${common};

      font-size: 15px;
      padding: 8px;
      font-weight: 500;

      svg {
        width: 18px;
        height: 18px;
        margin: 2px;
      }

      img {
        width: auto;
        height: 18px;
        margin: 2px;
      }

      span {
        margin: 0 6px;
      }
    `,
  },
  large: {
    styles: css`
      ${common};

      font-size: 16px;
      padding: 10px 12px;
      font-weight: 500;
      letter-spacing: 0.2px;

      span {
        margin: 0 6px;
      }

      svg {
        margin: 2px;
        width: 20px;
        height: 20px;
      }

      img {
        margin: 2px;
        width: auto;
        height: 20px;
      }
    `,
  },
};

export const variant: {
  [key in ButtonVariant]: {
    styles: SerializedStyles;
  };
} = {
  contained: {
    styles: css``,
  },
  outlined: {
    styles: css`
      border: 1px solid ${palette.gray400};

      &:hover {
        background-color: rgba(52, 73, 94, 0.05);
      }

      &:active {
        background-color: rgba(52, 73, 94, 0.08);
      }
    `,
  },
  text: {
    styles: css`
      border: 1px solid transparent;

      &:hover {
        background-color: rgba(52, 73, 94, 0.05);
      }

      &:active {
        background-color: rgba(52, 73, 94, 0.08);
      }
    `,
  },
};

export const color: {
  [key in ButtonVariant]: {
    [key in ButtonColor | 'disabled']: {
      styles: SerializedStyles;
    };
  };
} = {
  contained: {
    blue: {
      styles: css`
        color: white;
        background-color: ${palette.main_blue1};
        border: 1px solid ${palette.main_blue1};

        &:hover {
          transition: ease 0.2s;
          opacity: 0.9;
        }

        &:active {
          transition: ease 0.2s;
          opacity: 0.8;
        }
      `,
    },
    gray: {
      styles: css`
        color: ${palette.main_blue1};
        background-color: ${palette.gray200};
        border: 1px solid ${palette.gray200};

        &:hover {
          background-color: ${palette.gray300};
          border: 1px solid ${palette.gray300};
        }

        &:active {
          background-color: ${palette.gray400};
          border: 1px solid ${palette.gray400};
        }
      `,
    },
    black: {
      styles: css`
        color: ${palette.black1};
        background-color: ${palette.gray200};
        border: 1px solid ${palette.gray200};

        &:hover {
          background-color: ${palette.gray300};
          border: 1px solid ${palette.gray300};
        }

        &:active {
          background-color: ${palette.gray400};
          border: 1px solid ${palette.gray400};
        }
      `,
    },
    disabled: {
      styles: css`
        color: ${palette.gray500};
        background-color: ${palette.gray200};
        border: 1px solid ${palette.gray200};

        &:hover {
          background-color: ${palette.gray200};
          border: 1px solid ${palette.gray200};
        }
      `,
    },
  },
  outlined: {
    blue: {
      styles: css`
        color: ${palette.main_blue1};
      `,
    },
    gray: {
      styles: css`
        color: ${palette.gray800};

        svg {
          color: ${palette.gray800};
        }
      `,
    },
    black: {
      styles: css`
        color: ${palette.black1};
      `,
    },
    disabled: {
      styles: css`
        color: ${palette.gray500};

        &:hover {
          background-color: white !important;
        }

        svg {
          color: ${palette.gray500};
        }
      `,
    },
  },
  text: {
    blue: {
      styles: css`
        color: ${palette.main_blue1};
      `,
    },
    gray: {
      styles: css`
        color: ${palette.gray800};

        svg {
          color: ${palette.gray800};
        }
      `,
    },
    black: {
      styles: css`
        color: ${palette.black1};
      `,
    },
    disabled: {
      styles: css`
        color: ${palette.gray500};

        &:hover {
          background-color: transparent !important;
        }

        svg {
          color: ${palette.gray500};
        }
      `,
    },
  },
};
