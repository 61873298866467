/* @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { HTMLProps } from 'react';
import { InputProps } from './types';
import { variant as variantStyle, status as statusStyle } from './styles';
import styled from '@emotion/styled';
import palette from '../../styles/palette';

const FieldWrapper = styled.div`
  width: 100%;
`;

const Label = styled.label`
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.5;
  color: ${palette.gray700};
  padding-left: 10px;
  width: 100%;
`;

const InputWrapper = styled.div<{ error: boolean; hasTrailingIcon: boolean }>`
  position: relative;

  label + & {
    margin-top: 4px;
  }

  & + div {
    margin-top: 6px;
  }

  i {
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    top: 0;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  i:first-of-type {
    left: 12px;
    color: ${palette.gray500};

    svg {
      color: ${palette.gray500};
    }
  }

  i:first-of-type + input {
    padding-left: 36px;
  }

  input + i:last-of-type {
    right: 12px;
    left: auto;

    color: ${props => (!!props.error ? palette.red0 : palette.gray500)};

    svg {
      color: ${props => (!!props.error ? palette.red0 : palette.gray500)};
    }
  }

  input:nth-last-of-type(2) {
    padding-right: 36px;
  }
`;

const CommonInput = styled.input`
  height: 44px;
  font-size: 16px;
  padding: 10px 12px;
  border-radius: 4px;
  width: 100%;
  color: ${palette.black1};
  line-height: 1.5;

  &:hover {
    transition: ease-in-out 0.15s;
  }

  &:active {
    transition: ease-in-out 0.15s;
  }

  &:focus {
    transition: ease-in-out 0.15s;
  }

  &::placeholder {
    color: ${palette.gray500};
  }
`;

const MultilineInput = styled.textarea`
  width: 100%;
  resize: vertical;
  border-radius: 4px;
  padding: 10px 12px;
  font-size: 16px;
  color: ${palette.black1};
  line-height: 1.5;

  &:hover {
    transition: ease-in-out 0.15s;
  }

  &:active {
    transition: ease-in-out 0.15s;
  }

  &:focus {
    transition: ease-in-out 0.15s;
  }

  &::placeholder {
    color: ${palette.gray500};
  }
`;

const HelperText = styled.div<{ error: boolean }>`
  font-size: 14px;
  padding-left: 10px;
  color: ${props => (!!props.error ? palette.red0 : palette.gray600)};
  width: 100%;

  ${props =>
    props.error &&
    css`
      line-height: 1.5;
    `}
`;

const InputField: React.FC<InputProps> = props => {
  const { variant = 'outlined', multiline, helperText, labelText, error, trailingIcon, leadingIcon, ...inputProps } = props;

  const appliedInputStyle = [variantStyle[variant].styles];

  if (props.disabled) {
    appliedInputStyle.push(statusStyle[variant].disabled.styles);
  }

  if (!props.disabled && !!error) {
    appliedInputStyle.push(statusStyle[variant].error.styles);
  }

  return (
    <FieldWrapper>
      {labelText && <Label>{labelText.toUpperCase()}</Label>}
      <InputWrapper error={!!error} hasTrailingIcon={!multiline && !!trailingIcon}>
        {multiline || leadingIcon}
        {multiline ? (
          <MultilineInput {...(inputProps as HTMLProps<HTMLTextAreaElement>)} css={appliedInputStyle} />
        ) : (
          <CommonInput {...(inputProps as HTMLProps<HTMLInputElement>)} css={appliedInputStyle} />
        )}
        {multiline || trailingIcon}
      </InputWrapper>
      {(!!error || !!helperText) && <HelperText error={!!error}>{error || helperText}</HelperText>}
    </FieldWrapper>
  );
};

export default InputField;
