/* @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonProps, LinkProps, GeneralButtonProps, AnchorProps } from './types';
import { size as sizeStyle, color as colorStyle, variant as variantStyle } from './styles';
import ButtonSpinner from '../buttonSpinner';

const Button: React.FC<GeneralButtonProps> = props => {
  const { color = 'blue', variant = 'contained', size = 'medium', elementType, fullWidth, disabled, ...ownProps } = props;

  const appliedStyle = [sizeStyle[size].styles, colorStyle[variant][disabled ? 'disabled' : color].styles, variantStyle[variant].styles];

  if (disabled) {
    appliedStyle.push(css`
      cursor: no-drop;
    `);
  }

  if (fullWidth) {
    appliedStyle.push(
      css`
        width: 100%;
      `
    );
  }

  switch (elementType) {
    case 'link': {
      return (
        <Link {...(ownProps as LinkProps)} css={appliedStyle}>
          {props.children}
        </Link>
      );
    }

    case 'button': {
      const { isLoading, ...buttonProps } = ownProps as ButtonProps;

      if (isLoading) {
        const style = {
          ...buttonProps.style,
          position: 'relative',
        } as React.CSSProperties;

        return (
          <button {...(buttonProps as ButtonProps)} style={style} css={appliedStyle} disabled={true}>
            <div style={{ visibility: 'hidden', display: 'flex' }}>{props.children}</div>
            <ButtonSpinner color={color} size={size} variant={variant} disabled={disabled!} />
          </button>
        );
      }

      return (
        <button {...(buttonProps as ButtonProps)} css={appliedStyle} disabled={disabled}>
          {props.children}
        </button>
      );
    }

    case 'anchor': {
      return (
        <a css={appliedStyle} {...(ownProps as AnchorProps)}>
          {props.children}
        </a>
      );
    }

    default:
      return null;
  }
};

export default Button;
