/* @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { ButtonVariant, ButtonColor } from '../button/types';
import styled from '@emotion/styled';
import { combinedButtonLineColor } from './styles';

export interface GroupButtonProps {
  variant?: ButtonVariant;
  color?: ButtonColor;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
}

const GroupButtonWrapper = styled.div`
  display: inline-flex;

  a:not(:last-child),
  button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  a:not(:first-of-type),
  button:not(:first-of-type) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const GroupButton: React.FC<GroupButtonProps> = props => {
  const { children, variant = 'contained', color = 'blue', disabled = false, className, fullWidth } = props;

  const appliedStyle = [combinedButtonLineColor(variant, color, disabled)];

  if (fullWidth) {
    appliedStyle.push(css`
      width: 100%;
    `);
  }

  return (
    <GroupButtonWrapper className={className} css={appliedStyle}>
      {children}
    </GroupButtonWrapper>
  );
};

export default GroupButton;
