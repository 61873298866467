import React from 'react';
import { ButtonSize } from '../button/types';
import palette from '../../styles/palette';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';

interface ButtonSpinnerProps {
  color: string;
  size: ButtonSize;
  variant: string;
  disabled?: boolean;
  className?: string;
}

function getSpinnerColor(buttonColor: string, variant: string, disabled?: boolean) {
  if (disabled) return palette.gray500;

  if (variant === 'contained' && buttonColor === 'blue') return 'white';

  switch (buttonColor) {
    case 'blue':
      return palette.main_blue1;
    case 'gray':
      return palette.gray800;
    default:
      return palette.black1;
  }
}

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerRing = styled.div<{ size: ButtonSize }>`
  display: inline-block;
  width: 16px;
  height: 16px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 0;
    border-radius: 50%;
    animation: ${spinnerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 2px solid;
  }

  ${props =>
    props.size === 'medium' &&
    css`
      width: 20px;
      height: 20px;

      div {
        width: 20px;
        height: 20px;
      }
    `}

  ${props =>
    props.size === 'large' &&
    css`
      width: 20px;
      height: 20px;

      div {
        border-width: 2.5px;
        width: 20px;
        height: 20px;
      }
    `}

  div:nth-of-type(1) {
    animation-delay: -0.45s;
  }

  div:nth-of-type(2) {
    animation-delay: -0.3s;
  }

  div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
`;

const ButtonSpinner: React.FC<ButtonSpinnerProps> = ({ className, size, color = 'white', variant, disabled }) => {
  const spinnerColor = getSpinnerColor(color, variant, disabled);

  return (
    <SpinnerWrapper>
      <SpinnerRing size={size} className={className}>
        <div
          style={{
            borderColor: `${spinnerColor} transparent transparent transparent`,
          }}
        />
        <div
          style={{
            borderColor: `${spinnerColor} transparent transparent transparent`,
          }}
        />
        <div
          style={{
            borderColor: `${spinnerColor} transparent transparent transparent`,
          }}
        />
        <div
          style={{
            borderColor: `${spinnerColor} transparent transparent transparent`,
          }}
        />
      </SpinnerRing>
    </SpinnerWrapper>
  );
};

export default ButtonSpinner;
