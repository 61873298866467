const palette = {
  // Color Variables
  main_blue0: '#6096ff', // main color
  main_blue1: '#3e7fff',
  main_blue_light0: ' #81acff', // main color when hover
  main_blue_light1: '#ecf1fa',

  gray30: '#f8f9fb',
  gray50: '#f4f4f5',
  gray100: '#fafbfc',
  gray200: '#f1f3f6',
  gray300: '#e7eaef',
  gray400: '#d8dde7',
  gray500: '#bbc2d0',
  gray600: '#9aa3b5',
  gray700: '#7e8698',
  gray800: '#666d7c',

  black0: '#1e2a35', // primary text
  black1: '#34495e',

  navy0: '#133f7a', // link
  purple0: '#3e39ac', // visited link

  red0: '#e22319', // caution
  red1: '#e24336', // error alert
  green0: '#48d2a0', // success
  yellow0: '#ffcf48', // warning
};

export default palette;
